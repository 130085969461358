import React from 'react';

//Styles
import styles from './Button.module.scss';

//Components
import { Link } from 'gatsby';

//Images
import HandPointerRightIcon from '@images/icons/hand-pointer-right.svg';
import ArrowLeftWhiteIcon from '@images/icons/arrow-left-white.svg';
import ArrowLeftBlackIcon from '@images/icons/arrow-left-black.svg';
import ArrowRightUpWhite from '@images/icons/arrow-right-up-white.svg';

export const AnchorButton = props => {
    const {
        children,
        href,
        newTab,
        primary,
        secondary,
        tertiary,
        arrowLink,
        back,
        red,
        white,
        notAGatsbyLink,
        scrollTo,
        className,
        style = {},
    } = props;

    const renderedClassName = `${className ? className : ''} ${styles.button} ${
        primary
            ? styles.button__primary
            : secondary
            ? styles.button__secondary
            : tertiary
            ? styles.button__tertiary
            : back
            ? styles.button__back
            : arrowLink
            ? styles.button__arrowLink
            : ''
    } ${red && primary ? styles.button__primaryRed : ''} 
    ${white && tertiary ? styles.button__tertiaryWhite : ''}
    ${white && back ? styles.button__backWhite : ''}
    ${white && arrowLink ? styles.button__arrowLinkWhite : ''}
    `;

    if (newTab || scrollTo || notAGatsbyLink) {
        return (
            <a
                style={style}
                className={renderedClassName}
                href={href}
                {...(newTab && { target: '_blank' })}
                {...(newTab && { rel: 'noreferrer' })}
                onClick={() => {
                    scrollTo && scrollTo.current.scrollIntoView({ block: 'center' });
                }}
            >
                {tertiary && <HandPointerRightIcon />}
                {back && white ? <ArrowLeftWhiteIcon /> : back ? <ArrowLeftBlackIcon /> : ''}
                {children}
                {arrowLink ? <ArrowRightUpWhite /> : null}
            </a>
        );
    } else {
        return (
            <Link style={style} className={renderedClassName} to={href}>
                {tertiary && <HandPointerRightIcon />}
                {back && white ? <ArrowLeftWhiteIcon /> : back ? <ArrowLeftBlackIcon /> : ''}
                {children}
                {arrowLink ? <ArrowRightUpWhite /> : null}
            </Link>
        );
    }
};
