import React from 'react';

//Data
import { data } from '@data/en/pages/404';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { FourOFourContents } from '@components/FourOFourContents/FourOFourContents';
import { Footer } from '@components/Footer/Footer';

const FourOFour = () => {
    const { seo, text, button } = data;

    return (
        <>
            <SEO {...seo} />

            <Header />

            <FourOFourContents text={text} button={button} />

            <Footer />
        </>
    );
};

export default FourOFour;
