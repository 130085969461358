import React from 'react';

//Styles
import styles from './FourOFourContents.module.scss';

//Components
import { AnchorButton } from '@components/Buttons/AnchorButton';
import { Container } from '@components/Utilities/Container/Container';

export const FourOFourContents = props => {
    const { text, button } = props;

    return (
        <section className={styles.fourOFour}>
            <Container>
                <h1 className={`${styles.fourOFour__title}`}>
                    4<span>0</span>4
                </h1>

                <p className="big">{text}</p>

                <AnchorButton primary red notAGatsbyLink href={button.href}>
                    {button.text}
                </AnchorButton>
            </Container>
        </section>
    );
};
