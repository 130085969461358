export const data = {
    seo: {
        title: '404',
    },
    text: "Oops... Sorry we couldn't find this page for you",
    button: {
        text: 'Return to home',
        href: '/',
    },
};
